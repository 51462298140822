<template>
  <v-navigation-drawer
    :value="isDrawerOpen"
    app
    floating
    width="260"
    class="app-navigation-menu"
    :right="$vuetify.rtl"
    @input="val => $emit('update:is-drawer-open', val)"
  >
    <!-- Navigation Header -->
    <div class="vertical-nav-header d-flex items-center ps-6 pe-5 pt-5 pb-2">
      <router-link
        to="/"
        class="d-flex align-center text-decoration-none"
      >
        <v-img
          :src="require('@/assets/images/logos/logo.png')"
          max-height="30px"
          max-width="30px"
          alt="logo"
          contain
          eager
          class="app-logo me-3"
        ></v-img>
        <v-slide-x-transition>
          <h2 class="app-title text--primary">
            {{ appName }}
          </h2>
        </v-slide-x-transition>
      </router-link>
    </div>

    <!-- Navigation Items -->
    <v-list
      expand
      shaped
      class="vertical-nav-menu-items pr-5"
    >
      <nav-menu-link
        v-for="link in links"
        :key="link.name"
        :title="link.title"
        :to="{ name: link.name }"
        :icon="link.icon"
      ></nav-menu-link>
      <nav-menu-group
        v-if="userInfo.role !== 'super-admin' && can('statistics-list')"
        title="Statistics"
        :icon="icons.mdiChartBar"
      >
        <nav-menu-link
          class="ml-6"
          title="Transactions"
          :to="{ name: 'transaction-statistics' }"
          :icon="icons.mdiCashMultiple"
        ></nav-menu-link>
      </nav-menu-group>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters } from 'vuex'

import {
  mdiHomeOutline,
  mdiHomeGroup,
  mdiTable,
  mdiTableLarge,
  mdiAccountMultipleOutline,
  mdiRead,
  mdiCashMultiple,
  mdiDotsHexagon,
  mdiChatOutline,
  mdiCashCheck,
  mdiChartBar,
  mdiCash
} from '@mdi/js'
import NavMenuLink from './components/NavMenuLink.vue'
import NavMenuGroup from './components/NavMenuGroup.vue'
import hasPermission from '@/mixins/hasPermission'

export default {
  components: {
    NavMenuLink,
    NavMenuGroup,
  },
  mixins: [hasPermission],
  props: {
    isDrawerOpen: {
      type: Boolean,
      default: null,
    },
  },
  data() {
    return {
      icons: {
        mdiChartBar,
        mdiCashMultiple,
      },
    }
  },
  setup() {
    return {
      links: [],
    }
  },
  computed: {
    ...mapGetters(['appName', 'logo', 'isDarkThemeEnabled', 'userInfo']),
  },
  mounted() {
    if (this.userInfo.role === 'super-admin') {
      this.setSuperAdminNavItems()
    } else {
      this.setOptionalNavItems()
    }
  },
  methods: {
    setOptionalNavItems() {
      this.links.push({
        title: 'Dashboard',
        name: 'dashboard',
        icon: mdiHomeOutline,
      })
      if (this.can('meter-station-list')) {
        this.links.push({
          title: 'Stations',
          name: 'stations',
          icon: mdiHomeGroup,
        })
      }
      if (this.can('main-meter-list')) {
        this.links.push({
          title: 'Main Meters',
          name: 'main-meters',
          icon: mdiTableLarge,
        })
      }
      if (this.can('meter-list')) {
        this.links.push({
          title: 'Meters',
          name: 'meters',
          icon: mdiTable,
        })
      }
      this.links.push({
        title: 'Customers',
        name: 'customers',
        icon: mdiAccountMultipleOutline,
      })
      if (this.can('meter-reading-list')) {
        this.links.push({
          title: 'Billings',
          name: 'meter-readings',
          icon: mdiRead,
        })
      }
      if (this.can('meter-token-list')) {
        this.links.push({
          title: 'Meter Tokens',
          name: 'meter-tokens',
          icon: mdiDotsHexagon,
        })
      }
      if (this.can('connection-fee-list')) {
        this.links.push({
          title: 'Connection Fee',
          name: 'connection-fee',
          icon: mdiCashCheck,
        })
      }
      if (this.can('mpesa-transaction-list')) {
        this.links.push({
          title: 'Transactions',
          name: 'transactions',
          icon: mdiCashMultiple,
        })
      }
      if (this.can('sms-list')) {
        this.links.push({
          title: 'SMS',
          name: 'sms',
          icon: mdiChatOutline,
        })
      }
    },
    setSuperAdminNavItems() {
      this.links.push({
        title: 'Dashboard',
        name: 'super-admin-dashboard',
        icon: mdiHomeOutline,
      })
      this.links.push(

        {
          title: 'Resellers',
          name: 'resellers',
          icon: mdiAccountMultipleOutline,
        },
      )
      this.links.push(

        {
          title: 'Payments',
          name: 'payments',
          icon: mdiCash,
        },
      )
    },
  },
}
</script>

<style lang="scss" scoped>
.app-title {
  font-size: 1.25rem;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.3px;
}

// ? Adjust this `translateX` value to keep logo in center when vertical nav menu is collapsed (Value depends on your logo)
.app-logo {
  transition: all 0.18s ease-in-out;
  .v-navigation-drawer--mini-variant & {
    transform: translateX(-4px);
  }
}

@include theme(app-navigation-menu) using ($material) {
  background-color: map-deep-get($material, 'background');
}

.app-navigation-menu {
  .v-list-item {
    &.vertical-nav-menu-link {
      ::v-deep .v-list-item__icon {
        .v-icon {
          transition: none !important;
        }
      }
    }
  }
}

// You can remove below style
// Upgrade Banner
.app-navigation-menu {
  .upgrade-banner {
    position: absolute;
    bottom: 13px;
    left: 50%;
    transform: translateX(-50%);
  }
}
</style>
